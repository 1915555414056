// 合同列表
const getListURL = `${API_CONFIG.butlerBaseURL}staffManagement/getStaffContractList`
// 改变列表
const getChangeListURL = `${API_CONFIG.controlBaseURL}staffManagement/getStaffChangeLogList`
// 离职列表
const getDimissionListURL = `${API_CONFIG.controlBaseURL}staffManagement/getStaffResignRecord`
// 离职
const createDimissionURL = `${API_CONFIG.controlBaseURL}staffManagement/addStaffResignRecord`
// 新增合同
const createContract = `${API_CONFIG.controlBaseURL}staffManagement/addStaffContract`
// 编辑合同
const updateContract = `${API_CONFIG.controlBaseURL}staffManagement/updateStaffContract`
// 查询合同详情
const getDetailUrl = `${API_CONFIG.shopBaseURL}staffManagement/getStaffContractById`
// 上传
const uploadURL = `${API_CONFIG.uploadURL}/fileUploadNoLimit`
// 离职下拉
const getInitCodeWordListResign = `${API_CONFIG.controlBaseURL}staffManagement/getInitCodeWordListResign`
// 合同下拉
const getInitCodeWordListContract = `${API_CONFIG.controlBaseURL}staffManagement/getInitCodeWordListContract`
export {
  getListURL,
  getChangeListURL,
  createDimissionURL,
  createContract,
  getDetailUrl,
  updateContract,
  uploadURL,
  getDimissionListURL,
  getInitCodeWordListResign,
  getInitCodeWordListContract
}
