var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "staffChange-wrapper" },
    [
      _vm.edit
        ? _c(
            "form-panel",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "headerSlot",
                    fn: function () {
                      return [
                        _c("v-button", {
                          attrs: { text: "返回" },
                          on: { click: _vm.goBack },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2038972483
              ),
            },
            [
              _c(
                "div",
                [
                  _c("table-panel", {
                    ref: "tablePanel",
                    attrs: {
                      isSearching: _vm.isSearching,
                      headers: _vm.headers,
                      tableData: _vm.statusAry,
                      hasOperateColumn: false,
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _c(
            "form-panel",
            {
              ref: "form",
              attrs: {
                form: _vm.form,
                submitUrl: _vm.submitUrl,
                submitBefore: _vm.submitBefore,
                submitSuccess: _vm.submitSuccessFunc,
              },
            },
            [
              _c(
                "col2-detail",
                [
                  _c(
                    "col2-block",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "离职类型",
                            prop: "resignType",
                            rules: [
                              {
                                required: true,
                                message: "请选择离职类型",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.dimissionTypeOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.resignType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "resignType", $$v)
                              },
                              expression: "form.resignType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "离职原因",
                            prop: "resignReason",
                            rules: [
                              {
                                required: true,
                                message: "请填写离职原因",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "离职原因",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.resignReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "resignReason", $$v)
                              },
                              expression: "form.resignReason",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "退保类型",
                            prop: "insuranceExitType",
                            rules: [
                              {
                                required: true,
                                message: "请选择退保类型",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.policyTypeOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.insuranceExitType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "insuranceExitType", $$v)
                              },
                              expression: "form.insuranceExitType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "离职日期",
                            prop: "resignDate",
                            rules: [
                              {
                                required: true,
                                message: "请选择离职日期",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-datepicker", {
                            attrs: {
                              type: "date",
                              width: _vm.width,
                              format: "YYYY-MM-DD",
                            },
                            model: {
                              value: _vm.form.resignDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "resignDate", $$v)
                              },
                              expression: "form.resignDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "离职手续办理日期",
                            prop: "resignProcessDate",
                            rules: [
                              {
                                required: true,
                                message: "请选择离职手续办理日期",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-datepicker", {
                            attrs: {
                              type: "date",
                              width: _vm.width,
                              format: "YYYY-MM-DD",
                            },
                            model: {
                              value: _vm.form.resignProcessDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "resignProcessDate", $$v)
                              },
                              expression: "form.resignProcessDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "本月结算工资金额",
                            prop: "resignMonthSalary",
                            rules: [
                              {
                                required: true,
                                message: "请填写本月结算工资金额",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input-number", {
                            attrs: {
                              placeholder: "本月结算工资金额",
                              max: 99999999999999999999,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.resignMonthSalary,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "resignMonthSalary", $$v)
                              },
                              expression: "form.resignMonthSalary",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "备注",
                            prop: "remark",
                            rules: [
                              {
                                required: true,
                                message: "请填写备注",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: { placeholder: "备注", width: _vm.width },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }