var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "staffContract-wrapper" },
    [
      _c(
        "form-panel",
        {
          scopedSlots: _vm._u([
            {
              key: "headerSlot",
              fn: function () {
                return [
                  _c("v-button", {
                    attrs: { text: "新增" },
                    on: { click: _vm.create },
                  }),
                  _c("v-button", {
                    attrs: { text: "返回" },
                    on: { click: _vm.goBack },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c("table-panel", {
                ref: "tablePanel",
                attrs: {
                  isSearching: _vm.isSearching,
                  headers: _vm.headers,
                  tableData: _vm.statusAry,
                  hasOperateColumn: true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "operateSlot",
                    fn: function (scope) {
                      return [
                        _c("v-button", {
                          staticStyle: { "margin-right": "10px" },
                          attrs: { text: "编辑", type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.edit(scope.row)
                            },
                          },
                        }),
                        _c("v-button", {
                          attrs: { text: "查看", type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.look(scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.subFormDetailTitle,
            visible: _vm.dialogTableVisible,
            width: "620px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "pop-wrapper" },
            [
              _c(
                "form-panel",
                {
                  ref: "form",
                  attrs: {
                    form: _vm.form,
                    submitUrl: _vm.submitUrl,
                    queryUrl: _vm.queryUrl,
                    hasHeader: false,
                    submitSuccess: _vm.submitSuccessFunc,
                  },
                  on: { update: _vm.update },
                },
                [
                  !_vm.isLook
                    ? _c(
                        "col2-block",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "签订类型",
                                prop: "signType",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择签订类型",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.signTypeOps,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.signType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "signType", $$v)
                                  },
                                  expression: "form.signType",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "合同编号",
                                prop: "contractNum",
                                rules: [
                                  {
                                    required: true,
                                    message: "请填写合同编号",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-input", {
                                attrs: {
                                  placeholder: "合同编号",
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.contractNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contractNum", $$v)
                                  },
                                  expression: "form.contractNum",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "合同主体",
                                prop: "contractSubject",
                                rules: [
                                  {
                                    required: true,
                                    message: "请填写合同主体",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-input", {
                                attrs: {
                                  placeholder: "合同主体",
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.contractSubject,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contractSubject", $$v)
                                  },
                                  expression: "form.contractSubject",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "合同类型",
                                prop: "contractType",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择合同类型",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.contractTypeOps,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.contractType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contractType", $$v)
                                  },
                                  expression: "form.contractType",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "用工类型",
                                prop: "employmentType",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择用工类型",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.useTypeOps,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.employmentType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "employmentType", $$v)
                                  },
                                  expression: "form.employmentType",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "固定期限",
                                prop: "fixedTerm",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择固定期限",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.fixTimeOps,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.fixedTerm,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "fixedTerm", $$v)
                                  },
                                  expression: "form.fixedTerm",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "保密协议",
                                prop: "confidentialityAgreement",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择保密协议",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.fixTimeOps,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.confidentialityAgreement,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "confidentialityAgreement",
                                      $$v
                                    )
                                  },
                                  expression: "form.confidentialityAgreement",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "岗位协议",
                                prop: "jobAgreement",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择岗位协议",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.fixTimeOps,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.jobAgreement,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "jobAgreement", $$v)
                                  },
                                  expression: "form.jobAgreement",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "合同签订日期",
                                prop: "contractSignDate",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择合同签订日期",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-datepicker", {
                                attrs: {
                                  type: "date",
                                  width: _vm.width,
                                  format: "YYYY-MM-DD",
                                  maxDate: _vm.form.contractStartDate,
                                },
                                model: {
                                  value: _vm.form.contractSignDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contractSignDate", $$v)
                                  },
                                  expression: "form.contractSignDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "合同开始日期",
                                prop: "contractStartDate",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择合同开始日期",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-datepicker", {
                                attrs: {
                                  type: "date",
                                  width: _vm.width,
                                  minDate: _vm.form.contractSignDate,
                                  disabled: !_vm.form.contractSignDate,
                                  maxDate: _vm.form.contractEndDate,
                                  format: "YYYY-MM-DD",
                                },
                                model: {
                                  value: _vm.form.contractStartDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contractStartDate", $$v)
                                  },
                                  expression: "form.contractStartDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "合同结束日期",
                                prop: "contractEndDate",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择合同结束日期",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-datepicker", {
                                attrs: {
                                  type: "date",
                                  width: _vm.width,
                                  format: "YYYY-MM-DD",
                                  disabled: !_vm.form.contractStartDate,
                                  minDate: _vm.form.contractStartDate,
                                },
                                model: {
                                  value: _vm.form.contractEndDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contractEndDate", $$v)
                                  },
                                  expression: "form.contractEndDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "合同附件",
                                prop: "fileUrlList",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择合同附件",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-uploader2", {
                                attrs: {
                                  action: _vm.uploadURL,
                                  filePath: "customerFiles",
                                  tip: "最多允许上传10个文件,单个文件大小不允许超过50MB",
                                  limit: 10,
                                  beforeRemove: _vm.beforeRemove,
                                },
                                model: {
                                  value: _vm.form.fileUrlList,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "fileUrlList", $$v)
                                  },
                                  expression: "form.fileUrlList",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "备注",
                                prop: "remark",
                                rules: [
                                  {
                                    required: true,
                                    message: "请填写备注",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-input", {
                                attrs: {
                                  placeholder: "备注",
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "col2-block",
                        [
                          _c("col2-item", {
                            attrs: {
                              label: "签订类型",
                              span: 24,
                              text: _vm.detailItem.signType,
                            },
                          }),
                          _c("col2-item", {
                            attrs: {
                              span: 24,
                              label: "合同编号",
                              text: _vm.detailItem.contractNum,
                            },
                          }),
                          _c("col2-item", {
                            attrs: {
                              span: 24,
                              label: "合同主体",
                              text: _vm.detailItem.contractSubject,
                            },
                          }),
                          _c("col2-item", {
                            attrs: {
                              label: "合同类型",
                              span: 24,
                              text: _vm.detailItem.contractType,
                            },
                          }),
                          _c("col2-item", {
                            attrs: {
                              label: "用工类型",
                              span: 24,
                              text: _vm.detailItem.employmentType,
                            },
                          }),
                          _c("col2-item", {
                            attrs: {
                              label: "固定期限",
                              span: 24,
                              text: _vm.detailItem.fixedTerm,
                            },
                          }),
                          _c("col2-item", {
                            attrs: {
                              label: "保密协议",
                              span: 24,
                              text: _vm.detailItem.confidentialityAgreement,
                            },
                          }),
                          _c("col2-item", {
                            attrs: {
                              label: "岗位协议",
                              span: 24,
                              text: _vm.detailItem.jobAgreement,
                            },
                          }),
                          _c("col2-item", {
                            attrs: {
                              span: 24,
                              label: "合同签定日期",
                              text: _vm.detailItem.contractSignDate,
                            },
                          }),
                          _c("col2-item", {
                            attrs: {
                              span: 24,
                              label: "合同开始日期",
                              text: _vm.detailItem.contractStartDate,
                            },
                          }),
                          _c("col2-item", {
                            attrs: {
                              span: 24,
                              label: "合同结束日期",
                              text: _vm.detailItem.contractEndDate,
                            },
                          }),
                          _c(
                            "col2-item",
                            { attrs: { span: 24, label: "合同附件" } },
                            [
                              _c(
                                "div",
                                { staticClass: "list-wrapper" },
                                _vm._l(
                                  _vm.detailItem.fileUrlList,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.url,
                                        staticClass: "contract-file-wrapper",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "contract-file-text" },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(item.name) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                        _c("v-button", {
                                          attrs: {
                                            type: "text",
                                            text: "下载附件",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.downloadFile(item)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                          _c("col2-item", {
                            attrs: {
                              span: 24,
                              label: "备注",
                              text: _vm.detailItem.remark,
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }