<template>
  <div class="staffChange-wrapper">
    <!-- <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :hasOperateColumn="false"
      v-if="edit"
      @searchSuccess="searchSuccess"
    >
      <template #headerSlot>
        <v-button text="返回" @click="goBack"></v-button>
      </template>
    </list> -->
    <form-panel v-if="edit">
      <template #headerSlot>
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <div>
        <table-panel
          ref="tablePanel"
          :isSearching="isSearching"
          :headers="headers"
          :tableData="statusAry"
          :hasOperateColumn="false"
        ></table-panel>
      </div>
    </form-panel>
    <form-panel
      ref="form"
      :form="form"
      :submitUrl="submitUrl"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccessFunc"
      v-else
    >
      <col2-detail>
        <col2-block>
          <el-form-item
            label="离职类型"
            prop="resignType"
            :rules="[
              { required: true, message: '请选择离职类型', trigger: 'blur' },
            ]"
          >
            <v-select
              v-model="form.resignType"
              :options="dimissionTypeOps"
              :width="width"
            ></v-select>
          </el-form-item>
          <el-form-item
            label="离职原因"
            prop="resignReason"
            :rules="[
              { required: true, message: '请填写离职原因', trigger: 'blur' },
            ]"
          >
            <v-input
              placeholder="离职原因"
              v-model="form.resignReason"
              :width="width"
            />
          </el-form-item>
          <el-form-item
            label="退保类型"
            prop="insuranceExitType"
            :rules="[
              { required: true, message: '请选择退保类型', trigger: 'blur' },
            ]"
          >
            <v-select
              v-model="form.insuranceExitType"
              :options="policyTypeOps"
              :width="width"
            ></v-select>
          </el-form-item>
          <el-form-item
            label="离职日期"
            prop="resignDate"
            :rules="[
              { required: true, message: '请选择离职日期', trigger: 'change' },
            ]"
          >
            <v-datepicker
              v-model="form.resignDate"
              type="date"
              :width="width"
              format="YYYY-MM-DD"
            />
          </el-form-item>
          <el-form-item
            label="离职手续办理日期"
            prop="resignProcessDate"
            :rules="[
              {
                required: true,
                message: '请选择离职手续办理日期',
                trigger: 'change',
              },
            ]"
          >
            <v-datepicker
              v-model="form.resignProcessDate"
              type="date"
              :width="width"
              format="YYYY-MM-DD"
            />
          </el-form-item>
          <el-form-item
            label="本月结算工资金额"
            prop="resignMonthSalary"
            :rules="[
              {
                required: true,
                message: '请填写本月结算工资金额',
                trigger: 'blur',
              },
            ]"
          >
            <v-input-number
              placeholder="本月结算工资金额"
              v-model="form.resignMonthSalary"
              :max="99999999999999999999"
              :width="width"
            />
          </el-form-item>
          <el-form-item
            label="备注"
            prop="remark"
            :rules="[
              { required: true, message: '请填写备注', trigger: 'blur' },
            ]"
          >
            <v-input placeholder="备注" v-model="form.remark" :width="width" />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { getDimissionListURL, createDimissionURL ,getInitCodeWordListResign} from "./api";
import { Col2Block, Col2Detail,TablePanel } from "@/components/bussiness";
import { mapHelper } from "common/utils";

export default {
  name: "staffChangeList",
  components: {
    Col2Block,
    Col2Detail,
    TablePanel,
  },
  props: {
    staffId: {
      type: String,
      default: "",
    },
    enterpriseUserId:{
      type: String,
      default: "",
    }
  },
  data() {
    return {
      statusAry: [],
      isSearching: false,
      headers: [
        {
          prop: "userName",
          label: "姓名",
        },
        {
          prop: "resignType",
          label: "离职类型"
        },
        {
          prop: "resignReason",
          label: "离职原因",
        },
        {
          prop: "insuranceExitType",
          label: "退保类型",
        },
        {
          prop: "resignDate",
          label: "离职日期",
        },
        {
          prop: "resignProcessDate",
          label: "离职手续办理日期",
        },
        {
          prop: "resignMonthSalary",
          label: "本月结算工资金额",
        },
        {
          prop: "remark",
          label: "备注",
        },
        {
          prop: "insertUserName",
          label: "操作员",
        },
        {
          prop: "insertTime",
          label: "操作时间",
        },
      ],
      edit: true,
      width: 182,
      submitUrl: createDimissionURL,
      form: {
        enterpriseUserId: "",
        userId: "", // 员工基础表主键id
        resignType: undefined, // 离职类型
        insuranceExitType: undefined, // 退保类型 ：0 退保 1、转个人卡
        resignReason: "", // 离职原因
        resignMonthSalary: undefined, // 本月结算工资金额
        remark: "", // 备注
        resignProcessDate: "", // 离职手续办理日期
        resignDate: "", // 离职日期
      },
      dimissionTypeOps:[],
      policyTypeOps: []
    };
  },
  // created() {
  //   console.log(
  //     this.staffId,
  //     "this.commonProps.staffId = this.$route.query.id"
  //   );
  //   this.searchParams.staffId = this.staffId;
  //   this.form.staffId = this.staffId;
  // },
  mounted() {
    console.log('离职')
    //this.searchParams.userId = this.staffId;
    this.form.userId = this.staffId;
    this.form.enterpriseUserId = this.enterpriseUserId;
    this.fetchData();
    this.getInitInfo()
  },
  methods: {
    // searchSuccess(res) {
    //   if (res.data.resultList.length == 0) {
    //     this.edit = false;
    //   }
    // },
    getInitInfo () {
      this.$axios.get(getInitCodeWordListResign).then((res) => {
        console.log(res, 'res--')
        if (res.status === 100) {
          this.dimissionTypeOps = this.dealData(res.data.resignTypeList)
          this.policyTypeOps = this.dealData(res.data.insuranceExitTypeList)
        }
      })
    },
    dealData (arr) { // 处理数据使符合下拉框格式
      let list = []
      arr.forEach(item => {
        list.push({
          text: item,
          value: item
        })
      })
      const { setOps } = mapHelper.setMap(list)
      return list.length > 0 ? setOps(2) : list
    },
    submitBefore(data) {
      return true
    },
    goBack() {
      this.$router.go(-1);
    },
    submitSuccessFunc() {
      this.$message.success('保存成功')
      this.edit = true;
      this.fetchData()
      // this.dialogTableVisible = false;
      return false
    },
    fetchData() {
      this.$axios
        .get(`${getDimissionListURL}?userId=${this.staffId}`)
        .then((res) => {
          this.isSearching = false;
          if (String(res.status) === "100") {
            this.statusAry = [res.data]
            if (res.data==null) {
              this.edit = false;
            }
          }
        });
    }
  },
};
</script>
