import { mapHelper } from 'common/utils'

// 签订类型
const signType = [{
  text: '新签',
  value: '新签',
},
{
  text: '续签',
  value: '续签',
}
]

const {
  map: signTypeMap,
  setOps: signTypeOps
} = mapHelper.setMap(signType)

// 合同类型
const contractType = [{
  text: '公司劳务合同',
  value: '公司劳务合同',
},
{
  text: '公司劳动合同',
  value: '公司劳动合同',
},
{
  text: '派遣劳动合同',
  value: '派遣劳动合同',
},
{
  text: '聘用协议',
  value: '聘用协议',
},
{
  text: '实习协议',
  value: '实习协议',
},
{
  text: '其他',
  value: '其他',
}
]

const {
  map: contractTypeMap,
  setOps: contractTypeOps
} = mapHelper.setMap(contractType)

// 固定期限
const fixTime = [
  {
    text: '是',
    value: 1
  },
  {
    text: '否',
    value: 0
  }
]
const {
  map: fixTimeMap,
  setOps: fixTimeOps
} = mapHelper.setMap(fixTime)

// 用工类型
const useType = [
  {
    text: '正式工',
    value: '正式工',
  },
  {
    text: '临时工',
    value: '临时工',
  },
  {
    text: '实习生',
    value: '实习生',
  },
  {
    text: '劳务',
    value: '劳务',
  },
  {
    text: '其他',
    value: '其他',
  }
]
const {
  map: useTypeMap,
  setOps: useTypeOps
} = mapHelper.setMap(useType)

// 离职类型
const dimissionType = [
  {
    text: '辞职',
    value: '辞职',
  },
  {
    text: '商调',
    value: '商调',
  },
  {
    text: '辞退',
    value: '辞退',
  },
  {
    text: '退休',
    value: '退休',
  },
  {
    text: '终止',
    value: '终止',
  },
  {
    text: '业务外包',
    value: '业务外包',
  },
  {
    text: '项目撤出',
    value: '项目撤出',
  }
]
const {
  map: dimissionTypeMap,
  setOps: dimissionTypeOps
} = mapHelper.setMap(dimissionType)

// 退保类型
const policyType = [
  {
    text: '退保',
    value: 0
  },
  {
    text: '转个人卡',
    value: 1
  }
]
const {
  map: policyTypeMap,
  setOps: policyTypeOps
} = mapHelper.setMap(policyType)

// 变动类型
const changeType = [
  {
    text: '入职',
    value: 0
  },
  {
    text: '转正',
    value: 1
  },
  {
    text: '调动',
    value: 2
  },
  {
    text: '离职',
    value: 3
  }
]
const {
  map: changeTypeMap,
  setOps: changeTypeOps
} = mapHelper.setMap(changeType)

export {
  dimissionTypeMap, //离职类型
  dimissionTypeOps,
  policyTypeMap, // 退保类型
  policyTypeOps,
  signTypeMap,
  signTypeOps,
  contractTypeMap,
  contractTypeOps,
  fixTimeMap,
  fixTimeOps,
  useTypeMap,
  useTypeOps,
  changeTypeMap,
  changeTypeOps

}
