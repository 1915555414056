<template>
  <div class="staffChange-wrapper">
    <form-panel>
      <template #headerSlot>
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <div>
        <table-panel
          ref="tablePanel"
          :isSearching="isSearching"
          :headers="headers"
          :tableData="statusAry"
          :hasOperateColumn="false"
        ></table-panel>
      </div>
    </form-panel>
  </div>
</template>

<script>
import { getChangeListURL } from "./api";
import { TablePanel } from "@/components/bussiness";
export default {
  name: "staffChangeList",
  components: {
    TablePanel,
  },
  props: {
    staffId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      statusAry: [],
      isSearching: false,
      headers: [
        {
          prop: "userName",
          label: "姓名",
        },
        {
          prop: "changeType",
          label: "变动类型",
        },
        {
          prop: "originalDepartment",
          label: "原部门",
        },
        {
          prop: "originalJob",
          label: "原岗位",
        },
        {
          prop: "originalRank",
          label: "原职级",
        },
        {
          prop: "newDepartment",
          label: "新部门",
        },
        {
          prop: "newJob",
          label: "新岗位",
        },
        {
          prop: "newRank",
          label: "新职级",
        },
        {
          prop: "remark",
          label: "备注",
        },
        {
          prop: "insertUserName",
          label: "操作员",
        },
        {
          prop: "insertTime",
          label: "变动时间",
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$axios
        .get(`${getChangeListURL}?userId=${this.staffId}`)
        .then((res) => {
          this.isSearching = false;
          if (String(res.status) === "100") {
            this.statusAry = res.data;
          }
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
