<template>
  <div class="SurveyForm-container">
    <tabs-panel :keepAlive="false" :tabs="tabs" :tabRouter="false" :activeLabel.sync="activeLabel"></tabs-panel>
  </div>
</template>

<script>
import { TabsPanel } from 'components/bussiness'
import contract from './formTempalte/contract'
import change from './formTempalte/change'
import dimission from './formTempalte/dimission'

export default {
  name: 'SurveyForm',
  components: {
    TabsPanel
  },
  data () {
    return {
      tabs: [],
      commonProps: {
        staffId: '',
        enterpriseUserId: ''
      },
      activeLabel: '合同'
    }
  },
  created () {
    this.commonProps.staffId = this.$route.query.id
    this.commonProps.enterpriseUserId = this.$route.query.enterpriseUserId
    console.log(this.commonProps.staffId,'kkkkkk')
    this.tabs = [{
      label: '合同',
      component: contract,
      props: this.commonProps
    }, {
      label: '变动',
      component: change,
      props: this.commonProps
    }, {
      label: '离职',
      component: dimission,
      props: this.commonProps
    }]
  },
  methods: {
   
  }
}
</script>
