<template>
  <div class="staffContract-wrapper">
    <!-- <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" @click="create"></v-button>
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="edit(scope.row)"></v-button>
        <v-button text="查看" type="text" @click="look(scope.row)"></v-button>
      </template>
    </list> -->
    <form-panel>
      <template #headerSlot>
        <v-button text="新增" @click="create"></v-button>
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <div>
        <table-panel
          ref="tablePanel"
          :isSearching="isSearching"
          :headers="headers"
          :tableData="statusAry"
          :hasOperateColumn="true"
        >
        <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="edit(scope.row)" style="margin-right:10px;"></v-button>
        <v-button text="查看" type="text" @click="look(scope.row)"></v-button>
      </template>
      </table-panel>
      </div>
    </form-panel>
    <!-- 弹层 -->
    <el-dialog
      :title="subFormDetailTitle"
      :visible.sync="dialogTableVisible"
      width="620px"
    >
      <div class="pop-wrapper">
        <form-panel
          ref="form"
          :form="form"
          :submitUrl="submitUrl"
          :queryUrl="queryUrl"
          :hasHeader="false"
          @update="update"
          :submitSuccess="submitSuccessFunc"
        >
          <col2-block v-if="!isLook">
            <el-form-item
              label="签订类型"
              prop="signType"
              :rules="[
                { required: true, message: '请选择签订类型', trigger: 'blur' },
              ]"
            >
              <v-select
                v-model="form.signType"
                :options="signTypeOps"
                :width="width"
              ></v-select>
            </el-form-item>
            <el-form-item
              label="合同编号"
              prop="contractNum"
              :rules="[
                { required: true, message: '请填写合同编号', trigger: 'blur' },
              ]"
            >
              <v-input
                placeholder="合同编号"
                v-model="form.contractNum"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="合同主体"
              prop="contractSubject"
              :rules="[
                { required: true, message: '请填写合同主体', trigger: 'blur' },
              ]"
            >
              <v-input
                placeholder="合同主体"
                v-model="form.contractSubject"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="合同类型"
              prop="contractType"
              :rules="[
                { required: true, message: '请选择合同类型', trigger: 'blur' },
              ]"
            >
              <v-select
                v-model="form.contractType"
                :options="contractTypeOps"
                :width="width"
              ></v-select>
            </el-form-item>
            <el-form-item
              label="用工类型"
              prop="employmentType"
              :rules="[
                { required: true, message: '请选择用工类型', trigger: 'blur' },
              ]"
            >
              <v-select
                v-model="form.employmentType"
                :options="useTypeOps"
                :width="width"
              ></v-select>
            </el-form-item>
            <el-form-item
              label="固定期限"
              prop="fixedTerm"
              :rules="[
                { required: true, message: '请选择固定期限', trigger: 'blur' },
              ]"
            >
              <v-select
                v-model="form.fixedTerm"
                :options="fixTimeOps"
                :width="width"
              ></v-select>
            </el-form-item>
            <el-form-item
              label="保密协议"
              prop="confidentialityAgreement"
              :rules="[
                { required: true, message: '请选择保密协议', trigger: 'blur' },
              ]"
            >
              <v-select
                v-model="form.confidentialityAgreement"
                :options="fixTimeOps"
                :width="width"
              ></v-select>
            </el-form-item>
            <el-form-item
              label="岗位协议"
              prop="jobAgreement"
              :rules="[
                { required: true, message: '请选择岗位协议', trigger: 'blur' },
              ]"
            >
              <v-select
                v-model="form.jobAgreement"
                :options="fixTimeOps"
                :width="width"
              ></v-select>
            </el-form-item>
            <el-form-item
              label="合同签订日期"
              prop="contractSignDate"
              :rules="[
                {
                  required: true,
                  message: '请选择合同签订日期',
                  trigger: 'change',
                },
              ]"
            >
              <v-datepicker
                v-model="form.contractSignDate"
                type="date"
                :width="width"
                format="YYYY-MM-DD"
                :maxDate="form.contractStartDate"
              />
            </el-form-item>
            <el-form-item
              label="合同开始日期"
              prop="contractStartDate"
              :rules="[
                {
                  required: true,
                  message: '请选择合同开始日期',
                  trigger: 'change',
                },
              ]"
            >
              <v-datepicker
                v-model="form.contractStartDate"
                type="date"
                :width="width"
                :minDate="form.contractSignDate"
                :disabled="!form.contractSignDate"
                :maxDate="form.contractEndDate"
                format="YYYY-MM-DD"
              />
            </el-form-item>
            <el-form-item
              label="合同结束日期"
              prop="contractEndDate"
              :rules="[
                {
                  required: true,
                  message: '请选择合同结束日期',
                  trigger: 'change',
                },
              ]"
            >
              <v-datepicker
                v-model="form.contractEndDate"
                type="date"
                :width="width"
                format="YYYY-MM-DD"
                :disabled="!form.contractStartDate"
                :minDate="form.contractStartDate"
              />
            </el-form-item>
            <el-form-item
              label="合同附件"
              prop="fileUrlList"
              :rules="[
                {
                  required: true,
                  message: '请选择合同附件',
                  trigger: 'change',
                },
              ]"
            >
              <v-uploader2
                :action="uploadURL"
                filePath="customerFiles"
                v-model="form.fileUrlList"
                tip="最多允许上传10个文件,单个文件大小不允许超过50MB"
                :limit="10"
                :beforeRemove="beforeRemove"
              />
            </el-form-item>
            <el-form-item
              label="备注"
              prop="remark"
              :rules="[
                { required: true, message: '请填写备注', trigger: 'blur' },
              ]"
            >
              <v-input
                placeholder="备注"
                v-model="form.remark"
                :width="width"
              />
            </el-form-item>
          </col2-block>
          <col2-block v-else>
            <col2-item
              label="签订类型"
              :span="24"
              :text="detailItem.signType"
            />
            <col2-item :span="24" label="合同编号" :text="detailItem.contractNum" />
            <col2-item :span="24" label="合同主体" :text="detailItem.contractSubject" />
            <col2-item
              label="合同类型"
              :span="24"
              :text="detailItem.contractType"
            />
            <col2-item
              label="用工类型"
              :span="24"
              :text="detailItem.employmentType"
            />
            <col2-item
              label="固定期限"
              :span="24"
              :text="detailItem.fixedTerm"
            />
            <col2-item
              label="保密协议"
              :span="24"
              :text="detailItem.confidentialityAgreement"
            />
            <col2-item
              label="岗位协议"
              :span="24"
              :text="detailItem.jobAgreement"
            />
            <col2-item
              :span="24"
              label="合同签定日期"
              :text="detailItem.contractSignDate"
            />
            <col2-item
              :span="24"
              label="合同开始日期"
              :text="detailItem.contractStartDate"
            />
            <col2-item
              :span="24"
              label="合同结束日期"
              :text="detailItem.contractEndDate"
            />
            <col2-item :span="24" label="合同附件">
              <div class="list-wrapper">
                <div
                  class="contract-file-wrapper"
                  v-for="(item, index) in detailItem.fileUrlList"
                  :key="item.url"
                >
                  <span class="contract-file-text">
                    {{ item.name }}
                  </span>
                  <v-button
                    type="text"
                    text="下载附件"
                    @click="downloadFile(item)"
                  ></v-button>
                </div>
              </div>
            </col2-item>
            <col2-item :span="24" label="备注" :text="detailItem.remark" />
          </col2-block>
        </form-panel>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getListURL,
  createContract,
  getDetailUrl,
  updateContract,
  uploadURL,
  getInitCodeWordListContract
} from "./api";
import {
  signTypeMap,
  contractTypeMap,
  fixTimeMap,
  useTypeMap
} from "./map";
import { Col2Block, Col2Detail, Col2Item ,TablePanel} from "@/components/bussiness";
import { vUploader2 } from "components/control";
import { downloadHelper } from "common/utils";
import { mapHelper } from "common/utils";
export default {
  name: "primaryClassifyList",
  components: {
    Col2Block,
    Col2Detail,
    vUploader2,
    Col2Item,
    TablePanel
  },
  props: {
    staffId: {
      type: String,
      default: "",
    },
    enterpriseUserId:{
      type: String,
      default: "",
    }
  },
  data() {
    return {
      uploadURL,
      // searchUrl: getListURL,
      // searchParams: {
      //   userId: "",
      // },
      statusAry: [],
      isSearching: false,
      headers: [
        {
          prop: "userName",
          label: "姓名",
        },
        {
          prop: "contractNum",
          label: "合同编号",
        },
        {
          prop: "contractType",
          label: "合同类型",
          // formatter(row) {
          //   return contractTypeMap[row.status];
          // },
        },
        {
          prop: "contractSignDate",
          label: "合同签订日期",
        },
        {
          prop: "contractStartDate",
          label: "合同开始时间",
        },
        {
          prop: "contractEndDate",
          label: "合同结束时间",
        },
        {
          prop: "contractExpired",
          label: "合同是否到期"
        },
        {
          prop: "updateUserName",
          label: "操作员",
        },
        {
          prop: "updateTime",
          label: "操作时间",
        },
      ],
      width: 182,
      submitUrl: createContract,
      queryUrl: getDetailUrl,
      dialogTableVisible: false,
      subFormDetailTitle: "新增合同",
      form: {
        enterpriseUserId: "",
        userId: "", // 员工基础表主键id
        signType: undefined,
        contractNum: "",
        contractSubject: "",
        contractType: undefined,
        employmentType: undefined,
        fixedTerm: undefined,
        confidentialityAgreement: undefined,
        jobAgreement: undefined,
        contractSignDate: "",
        contractStartDate: "",
        contractEndDate: "",
        fileUrlList: [],
        remark: "",
      },
      signTypeOps: [],
      contractTypeOps: [],
      fixTimeOps: [],
      useTypeOps: [],
      contractTypeMap,
      signTypeMap,
      useTypeMap,
      fixTimeMap,
      detailItem: {},
      isLook: false, //是否为查看标记位
    };
  },
  created() {
    console.log('created')
    // this.searchParams.staffId = this.staffId;
    this.form.staffId = this.staffId;
  },
  mounted() {
    console.log('mounted合同')
    this.form.userId = this.staffId;
    this.form.enterpriseUserId = this.enterpriseUserId;
    this.fetchData()
    this.getInitInfo()
  },
  methods: {
    getInitInfo () {
      this.$axios.get(getInitCodeWordListContract).then((res) => {
        if (res.status === 100) {
          this.signTypeOps = this.dealData(res.data.signTypeList)
          this.contractTypeOps = this.dealData(res.data.contractTypeList)
          this.fixTimeOps = this.dealData(res.data.yesOrNoList)
          this.useTypeOps = this.dealData(res.data.employmentTypeList)
        }
      })
    },
    dealData (arr) { // 处理数据使符合下拉框格式
      let list = []
      arr.forEach(item => {
        list.push({
          text: item,
          value: item
        })
      })
      const { setOps } = mapHelper.setMap(list)
      return list.length > 0 ? setOps(2) : list
    },
    fetchData() {
      this.$axios
        .get(`${getListURL}?userId=${this.staffId}`)
        .then((res) => {
          this.isSearching = false;
          if (String(res.status) === "100") {
            this.statusAry = res.data          
          }
        });
    },
    create() {
      this.isLook = false;
      this.dialogTableVisible = true;
      this.submitUrl = createContract;
      this.subFormDetailTitle= "新增合同"
      setTimeout(() => {
        this.$refs.form.resetForm();
      }, 10);
    },
    edit(row) {
      this.isLook = false;
      this.dialogTableVisible = true;
      this.submitUrl = updateContract;
      this.subFormDetailTitle= "编辑合同"
      this.form.id = row.id
      setTimeout(() => {
        this.$refs.form.getData({ id: row.id, staffId: this.staffId });
      }, 10);
    },
    look(row) {
      this.isLook = true;
      this.dialogTableVisible = true;
      this.submitUrl = "";
      this.subFormDetailTitle= "查看合同"
      setTimeout(() => {
        this.$refs.form.getData({ id: row.id, staffId: this.staffId });
      }, 10);
    },
    update(data) {
      this.form = Object.assign(this.form, data);
      this.detailItem = data;
      this.detailItem.fileUrlList = this.detailItem.fileUrlList.map(
        (item) => {
          let vArr = item.split("/");
          return {
            name: vArr[vArr.length - 1],
            url: item,
          };
        }
      );
    },
    async beforeRemove() {
      // 上传文件删除前
      const isOk = await this.$confirm("此操作将永久删除该文件, 是否继续？");
      return isOk;
    },
    downloadFile(item) {
      console.log(item,'9999')
      // 附件下载
      let fileName = item.name;
      // downloadHelper.downloadFile({
      //   data: item.url,
      //   target: "_block",
      //   fileName,
      // });

      downloadHelper.downloadBySrc({
        src: item.url,
        target: '_block',
        fileName
      })
    },
    goBack() {
      this.$router.go(-1);
    },
    submitSuccessFunc() {
      // let _this=this
      this.$message.success('保存成功')
      this.fetchData()
      this.dialogTableVisible = false;
      return false
    }
    
  },
};
</script>
<style>
.mx-datepicker-popup {
  z-index: 5000;
}
.list-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  .contract-file-wrapper {
    display: flex;
    .contract-file-text {
      margin-right: 50px;
    }
  }
}
</style>
